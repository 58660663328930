import React, { FC } from "react";
import classNames from "classnames";
import { camelCase, upperFirst } from "lodash";

import { Section, SectionPattern, SectionProps } from "components";

import {
  HeroActions,
  HeroActionsProps,
  HeroCaption,
  HeroCaptionProps,
} from "./components";
import styles from "./Hero.module.css";

export type HeroPattern =
  | "card"
  | "cta"
  | "footer"
  | "form"
  | "legendary"
  | "page"
  | "post"
  | "section";

export type HeroSpan =
  | "auto"
  | "full"
  | "eleven-twelfths"
  | "three-fourths"
  | "two-thirds"
  | "half"
  | "one-third"
  | "one-fourth";

export interface HeroProps extends Omit<SectionProps, "pattern" | "span"> {
  actions?: HeroActionsProps;
  caption?: HeroCaptionProps;
  pattern?: HeroPattern;
  section?: SectionPattern;
  span?: HeroSpan;
}

export const Hero: FC<HeroProps> = ({
  actions,
  as = "header",
  caption,
  children,
  className,
  is = "hero",
  pattern = "section",
  section = "hero",
  span,
  ...rest
}) => (
  <Section
    as={as}
    is={is}
    pattern={section}
    {...(rest as SectionProps)}
    className={classNames(
      styles.hero,
      styles[pattern],
      span && styles[`span${upperFirst(camelCase(span))}`],
      className
    )}
  >
    {caption && <HeroCaption {...caption} />}
    {children}
    {actions && <HeroActions {...actions} />}
  </Section>
);
