import React, { FC } from "react";
import classNames from "classnames";
import { filter } from "lodash";

import {
  Grid,
  Link,
  Section,
  SectionProps,
  ServiceCard,
  Text,
} from "components";
import { useServicesQuery } from "graphql";

import { SectionContainerProps } from "../types";

export type ServiceCategory = "Commercial" | "Residential";

export interface ServicesSectionProps extends SectionContainerProps {
  category?: ServiceCategory;
  linkClassName?: string;
  showCaption?: boolean;
}

export const ServicesSection: FC<ServicesSectionProps> = ({
  category,
  children,
  heading = "Our Structural Engineers Are Here to Help",
  subheading = "We collaborate with homeowners, contractors, architects, and engineers to get projects ready on time and on budget. Our value-based approach has helped customers save thousands on projects, and we'll do the same for you. Here's some of what we offer.",
  linkClassName,
  showCaption = true,
  ...rest
}) => {
  let { edges } = useServicesQuery();
  if (category) {
    edges = filter(
      edges,
      ({
        node: {
          frontmatter: { categories },
        },
      }) => categories.includes(category)
    );
  }

  return (
    <Section pattern="feature" {...(rest as SectionProps)}>
      {showCaption && (
        <figcaption>
          {heading && (
            <Text as="h3" mod="mb-4 md:mb-5 xl:mb-6" pattern="title">
              {heading}
            </Text>
          )}
          {subheading && <Text as="h4">{subheading}</Text>}
        </figcaption>
      )}
      {children}
      {edges?.length && (
        <Grid
          cols="sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-5 xl:gap-6 mt-4 md:mt-6 xl:mt-8"
          mod="text-center"
        >
          {edges.map(
            ({
              node: {
                frontmatter: { description, icon, title },
                id,
                slug,
              },
            }) => (
              <Link
                activeClassName="hidden"
                className={linkClassName}
                to={`/${slug}`}
                pattern="box"
                key={id}
              >
                <ServiceCard
                  description={description}
                  icon={icon}
                  title={title}
                />
              </Link>
            )
          )}
        </Grid>
      )}
    </Section>
  );
};
