import { useStaticQuery, graphql } from "gatsby";

import { IconName } from "components";

export type JobTitle = "Drafter" | "EIT" | "Structural Engineer";

export type JobFrontmatter = {
  active: boolean;
  icon?: IconName;
  order: number;
  title: JobTitle;
};

export type JobNode = {
  node: {
    frontmatter: JobFrontmatter;
    id: string;
    slug: string;
  };
};

export interface JobsData {
  jobs: {
    edges: JobNode[];
  };
}

export const useJobsQuery = () => {
  const { jobs }: JobsData = useStaticQuery(
    graphql`
      query JobsQuery {
        jobs: allMdx(
          filter: {
            slug: { regex: "/jobs/" }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              frontmatter {
                active
                icon
                order
                title
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return jobs;
};
