import { useStaticQuery, graphql } from "gatsby";

import { IconName } from "components";

export type TradeName =
  | "Architects"
  | "Contractors"
  | "Electrical Engineers"
  | "Mechanical Engineers"
  | "Plumbing Engineers";

export type TradeFrontmatter = {
  active: boolean;
  description?: string;
  icon?: IconName;
  name: TradeName;
  order: number;
};

export type TradeNode = {
  node: {
    frontmatter: TradeFrontmatter;
    id: string;
    slug: string;
  };
};

export interface TradesData {
  trades: {
    edges: TradeNode[];
  };
}

export const useTradesQuery = () => {
  const { trades }: TradesData = useStaticQuery(
    graphql`
      query TradesQuery {
        trades: allMdx(
          filter: {
            slug: { regex: "/trades/" }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              frontmatter {
                active
                description
                icon
                name
                order
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return trades;
};
