import React, { FC, useState } from "react";
import classNames from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";

import { Listbox, Transition } from "@headlessui/react";

import {
  Form,
  FormProps,
  FormField,
  Grid,
  Pattern,
  Section,
  SectionProps,
  Text,
  encodeData,
} from "components";
import { useContactFormQuery } from "graphql";

import styles from "./ContactForm.module.css";

// TODO: move select markup to SelectField component

type FormData = {
  name: string;
  email: string;
  phone?: string;
  projectType?: string;
  projectLocation: string;
  projectDetails: string;
};

export interface ContactFormProps extends SectionProps {
  form?: FormProps;
}

export const ContactForm: FC<ContactFormProps> = ({
  className,
  form,
  ...rest
}) => {
  const {
    action,
    button,
    // TODO: integrate with form builder
    // fields,
    heading,
    method,
    name,
    subheading,
  } = useContactFormQuery();
  const { register, handleSubmit, errors, formState } = useForm<FormData>();
  const [selected, setSelected] = useState("Commercial");
  const [captured, setCaptured] = useState("");

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (Object.keys(data).length > 0) {
      const options = {
        method,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeData({
          "form-name": name,
          "bot-field": captured,
          projectType: selected,
          ...data,
        }),
      };

      fetch("/", options)
        .then(() => window.location.assign(action))
        .catch((error) => console.error(error));
    } else {
      console.error(JSON.stringify(errors));
    }
  };

  const sectionProps: SectionProps = {
    pattern: "form",
    ...rest,
  };

  const formProps: FormProps = {
    action,
    method,
    name,
    button,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitSuccessful: formState.isSubmitSuccessful,
    // successMessage: "Form data submitted, check your console.",
    ...form,
  };

  return (
    <Section
      {...(sectionProps as SectionProps)}
      className={classNames(styles.section, className)}
    >
      {(heading || subheading) && (
        <Pattern as="figcaption" is="caption">
          {heading && (
            <Text as="h1" pattern="title">
              {heading}
            </Text>
          )}
          {subheading && (
            <Text as="h2" pattern="subtitle" className={styles.subheading}>
              {subheading}
            </Text>
          )}
        </Pattern>
      )}
      <Form
        {...formProps}
        className={classNames(styles.form, formProps.className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="form-name" value={name} />
        <div hidden>
          <input
            name="bot-field"
            onChange={(event) => setCaptured(event.target.value)}
          />
        </div>
        <Grid className={styles.grid}>
          <FormField
            label="Full name"
            name="name"
            error={errors.name}
            errorMessage="Please enter your full name"
            register={register({ required: true })}
          />
          <FormField
            label="Email address"
            name="email"
            error={errors.email}
            errorMessage="Please enter an email address"
            register={register({ required: true })}
            type="email"
          />
          <FormField
            label="Phone number"
            name="phone"
            error={errors.phone}
            register={register({ required: false })}
            type="tel"
          />
          <FormField label="Project type" name="projectType">
            <input type="hidden" name="projectType" value={selected} />
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <Listbox.Button
                    className={classNames(
                      "cursor-default relative w-full border-2 bg-white p-2 md:p-3 xl:p-4 text-left focus:outline-none focus:shadow-outline-blue focus:border-primary transition-colors duration-150 ease-in-out",
                      open && "border-primary"
                    )}
                  >
                    {selected}
                  </Listbox.Button>
                  <Transition
                    show={open}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={classNames(styles.trans, "shadow-lg")}
                  >
                    <Listbox.Options className="max-h-60 p-2 md:p-3 xl:p-4 leading-6 shadow-xs overflow-auto focus:outline-none">
                      <Listbox.Option
                        className="cursor-pointer mb-2 outline-none hover:text-background-primary transition-colors duration-150 ease-in-out"
                        value="Commercial"
                      >
                        Commercial
                      </Listbox.Option>
                      <Listbox.Option
                        className="cursor-pointer outline-none hover:text-background-primary transition-colors duration-150 ease-in-out"
                        value="Residential"
                      >
                        Residential
                      </Listbox.Option>
                    </Listbox.Options>
                  </Transition>
                </>
              )}
            </Listbox>
          </FormField>
          <FormField
            label="Project location"
            name="projectLocation"
            error={errors.projectLocation}
            errorMessage="Please enter a location for your project"
            mod="col-span-full"
            register={register({ required: true })}
          />
          <FormField
            label="Project details"
            name="projectDetails"
            error={errors.projectDetails}
            errorMessage="Please give us some details about your project"
            register={register({ required: true })}
            type="textarea"
            mod="col-span-full"
          />
        </Grid>
      </Form>
    </Section>
  );
};
