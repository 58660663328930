import React, { FC } from "react";

import { AffiliateCard, Grid, Link, Section, SectionProps } from "components";
import { AffiliateNode, useAffiliatesQuery } from "graphql";

import { SectionContainerProps } from "../types";

export interface FeaturedAffiliatesSectionProps extends SectionContainerProps {
  featured: string[];
}

export const getFeaturedAffiliatesData = (
  affiliates: AffiliateNode[],
  feautedAffiliates: string[]
) =>
  affiliates.filter(
    ({
      node: {
        frontmatter: { name },
      },
    }) => feautedAffiliates.includes(name)
  );

export const FeaturedAffiliatesSection: FC<FeaturedAffiliatesSectionProps> = ({
  children,
  featured,
  ...rest
}) => {
  const { edges } = useAffiliatesQuery();
  const featuredAffiliates = getFeaturedAffiliatesData(edges, featured);

  return (
    <Section {...(rest as SectionProps)}>
      {children}
      {featuredAffiliates?.length && (
        <Grid cols="grid-cols-2 sm:grid-cols-4 gap-4 xs:gap-5 md:gap-6">
          {featuredAffiliates.map(
            ({
              node: {
                frontmatter: { image, name, url },
                id,
              },
            }) =>
              url ? (
                <Link key={id} pattern="box" to={url}>
                  <AffiliateCard image={image} name={name} showName={false} />
                </Link>
              ) : (
                <AffiliateCard
                  key={id}
                  image={image}
                  name={name}
                  showName={false}
                />
              )
          )}
        </Grid>
      )}
    </Section>
  );
};
