import React, { FC } from "react";
import classNames from "classnames";

import { Media, MediaProps, Pattern, PatternProps } from "components";

import styles from "./Section.module.css";

export type SectionPattern =
  | "container"
  | "content"
  | "cta"
  | "error"
  | "feature"
  | "form"
  | "heel"
  | "hero"
  | "layout"
  | "main"
  | "navbar"
  | "simple"
  | string;

export type SectionModifier =
  | "intro"
  | "landing"
  | "page"
  | "service"
  | boolean
  | string;

export interface SectionProps extends PatternProps {
  container?: PatternProps;
  isContained?: boolean;
  media?: MediaProps;
  observer?: object;
  pattern?: SectionPattern;
  modifier?: SectionModifier;
  spring?: object;
}

export const Section: FC<SectionProps> = ({
  as = "section",
  media,
  children,
  className,
  container,
  is = "section",
  isContained,
  observer,
  of,
  on,
  modifier,
  pattern = "content",
  spring,
  ...rest
}) => (
  <Pattern
    as={as}
    is={is}
    of={of}
    on={on}
    {...(rest as PatternProps)}
    className={classNames(
      styles.section,
      styles[pattern],
      modifier &&
        typeof modifier === "string" &&
        modifier.split(" ").map((mod) => styles[mod]),
      className
    )}
  >
    {media && (
      <Media {...media} className={classNames(styles.media, media.className)} />
    )}
    <Pattern
      is={isContained ? undefined : "container"}
      {...container}
      className={classNames(styles.container, container?.className)}
    >
      {children}
    </Pattern>
  </Pattern>
);
