import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Text } from "components";

import styles from "./ProcessCard.module.css";

export interface ProcessCardProps extends CardProps {
  description?: string;
  order: number;
  title: string;
}

export const ProcessCard: FC<ProcessCardProps> = ({
  children,
  className,
  description,
  order,
  title,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={classNames(styles.card, className)}>
    {children}
    <Text pattern="heading">{order + 1}</Text>
    <Text as="h3" pattern="subtitle" className={styles.title}>
      {title}
    </Text>
    {description && (
      <Text as="p" className={styles.description}>
        {description}
      </Text>
    )}
  </Card>
);
