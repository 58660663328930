import React, { FC, ReactNode } from "react";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";

import "@brainhubeu/react-carousel/lib/style.css";

import { Box, Media, Section, SectionProps, Text } from "components";
import { useCupolexMediaQuery } from "graphql";

import "./carousel.css";
import styles from "./CupolexCarousel.module.css";
import classNames from "classnames";

export interface CupolexCarouselProps extends SectionProps {
  footnote?: Element | ReactNode | string;
  heading?: string;
}

export const CupolexCarousel: FC<CupolexCarouselProps> = ({
  children,
  className,
  footnote,
  heading,
}) => {
  const { preview1, preview2, preview3, preview4, preview5 } =
    useCupolexMediaQuery();

  return (
    <Section
      on="page-cupolex"
      pattern="heel"
      className={classNames(styles.section, className)}
      isContained
      container={{ className: "w-full" }}
    >
      {heading && (
        <Box as="figcaption" className="container">
          <Text as="h4" pattern="subheading" mod="my-4 md:my-5 xl:my-6">
            {heading}
          </Text>
        </Box>
      )}
      {children}
      {typeof window !== "undefined" && (
        <Carousel
          plugins={[
            "arrows",
            "infinite",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2,
              },
            },
          ]}
        >
          <Media className={styles.media} image={preview1} />
          <Media className={styles.media} image={preview2} />
          <Media className={styles.media} image={preview3} />
          <Media className={styles.media} image={preview4} />
          <Media className={styles.media} image={preview5} />
        </Carousel>
      )}
      {footnote && (
        <Box className="container">
          <Text mod="mt-2 md:my-3 xl:my-4">{footnote}</Text>
        </Box>
      )}
    </Section>
  );
};
