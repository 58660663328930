import React, { FC } from "react";

import { Card, Grid, Media, Section, SectionProps, Text } from "components";
import { useCupolexMediaQuery } from "graphql";
import classNames from "classnames";

export type CupolexProblem = {
  cracks: string;
  sagging: string;
  corrosion: string;
};

export interface CupolexProblemsProps extends SectionProps {
  heading?: string;
  problems: CupolexProblem;
}

export const CupolexProblems: FC<CupolexProblemsProps> = ({
  children,
  className,
  heading,
  problems,
}) => {
  const { cracks, sagging, corrosion } = useCupolexMediaQuery();

  return (
    <Section on="page-cupolex" className={classNames("bg-gray-25", className)}>
      {heading && (
        <Text
          as="h4"
          mod="text-center mb-4 md:mb-6 xl:mb-8"
          pattern="subheading"
        >
          {heading}
        </Text>
      )}
      {children}
      <Grid mod="md:grid-cols-3 gap-4 md:gap-6 xl:gap-8">
        <Card>
          <Media image={cracks} />
          <Text as="p" mod="my-2 md:mt-3 xl:mt-4">
            <strong>Cracks</strong> {problems.cracks}
          </Text>
        </Card>
        <Card>
          <Media image={sagging} />
          <Text as="p" mod="my-2 md:mt-3 xl:mt-4">
            <strong>Weak-Spots</strong> {problems.sagging}
          </Text>
        </Card>
        <Card>
          <Media image={corrosion} />
          <Text mod="my-2 md:mt-3 xl:mt-4" as="p">
            <strong>Rust & Corrosion</strong> {problems.corrosion}
          </Text>
        </Card>
      </Grid>
    </Section>
  );
};
