import React, { FC } from "react";
import { MDXProvider, MDXProviderComponents } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import classNames from "classnames";

import {
  AuthorCard,
  AuthorCardProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Card,
  CardProps,
  CTA,
  CTAProps,
  Element,
  ElementProps,
  Form,
  FormProps,
  FormField,
  FormFieldProps,
  Grid,
  GridProps,
  Hero,
  HeroProps,
  Icon,
  IconProps,
  Link,
  LinkProps,
  List,
  ListProps,
  ListItem,
  ListItemProps,
  Media,
  MediaProps,
  PageHero,
  PageHeroProps,
  PageIntro,
  PageIntroProps,
  Pattern,
  PatternProps,
  PostCard,
  PostCardProps,
  Section,
  SectionProps,
  ServiceCard,
  ServiceCardProps,
  Text,
  TextProps,
  TradeCard,
  TradeCardProps,
} from "components";
import {
  AffiliatesSection,
  Brand,
  BrandProps,
  CommercialServicesCTA,
  ContactFooter,
  ContactForm,
  CupolexBanner,
  CupolexBannerProps,
  CupolexCarousel,
  CupolexCarouselProps,
  CupolexContact,
  CupolexIntro,
  CupolexIssue,
  CupolexIssueProps,
  CupolexProblems,
  CupolexProblemsProps,
  JobsSection,
  JobsSectionProps,
  LandingHero,
  MembersSection,
  MemorialSection,
  NewsSection,
  ResidentialServicesCTA,
  SectionContainerProps,
  ServicesSection,
  ServicesSectionProps,
  StatsSection,
  TradesSection,
} from "containers";

import styles from "./MDX.module.css";

export interface MDXProps extends PatternProps {
  body?: string;
  components?: MDXProviderComponents;
}

export const components: MDXProviderComponents = {
  a: (props: LinkProps) => <Link {...props} />,
  h1: (props: TextProps) => (
    <Text
      as="h1"
      mod="mb-4 mt-8 md:mb-6 md:mt-10 xl:mb-8 xl:mt-12"
      pattern="hero"
      {...props}
    />
  ),
  h2: (props: TextProps) => (
    <Text
      as="h2"
      mod="mb-4 mt-6 md:mb-6 md:mt-8 xl:mb-8 xl:mt-10"
      pattern="heading"
      {...props}
    />
  ),
  h3: (props: TextProps) => (
    <Text as="h3" mod="my-4 md:my-6 xl:my-8" pattern="title" {...props} />
  ),
  h4: (props: TextProps) => (
    <Text as="h4" mod="my-4 md:my-6 xl:my-8" pattern="subheading" {...props} />
  ),
  h5: (props: TextProps) => (
    <Text as="h5" mod="my-4 md:my-6 xl:my-8" pattern="subtitle" {...props} />
  ),
  h6: (props: TextProps) => (
    <Text
      as="h6"
      className={styles.h6}
      mod="my-4 md:my-6 xl:my-8"
      pattern="body"
      {...props}
    />
  ),
  hr: (props: PatternProps) => (
    <Pattern as="hr" is="divider" mod="my-4 md:my-6 xl:my-8" {...props} />
  ),
  li: (props: ListItemProps) => <ListItem {...props} />,
  ol: (props: ListProps) => (
    <List as="ol" mod="mb-4 md:mb-6 xl:mb-8 pl-5 xl:pl-6" {...props} />
  ),
  p: (props: TextProps) => (
    <Text as="p" mod="mb-4 md:mb-6 xl:mb-8" {...props} />
  ),
  ul: (props: ListProps) => (
    <List mod="mb-4 md:mb-6 xl:mb-8 pl-5 xl:pl-6" {...props} />
  ),
};

export const componentShortcodes = {
  AuthorCard: (props: AuthorCardProps) => <AuthorCard {...props} />,
  Box: (props: BoxProps) => <Box {...props} />,
  Button: (props: ButtonProps) => <Button {...props} />,
  Card: (props: CardProps) => <Card {...props} />,
  CTA: (props: CTAProps) => <CTA {...props} />,
  Element: (props: ElementProps) => <Element {...props} />,
  Form: (props: FormProps) => <Form {...props} />,
  FormField: (props: FormFieldProps) => <FormField {...props} />,
  Grid: (props: GridProps) => <Grid {...props} />,
  Hero: (props: HeroProps) => <Hero {...props} />,
  Icon: (props: IconProps) => <Icon {...props} />,
  Link: (props: LinkProps) => <Link {...props} />,
  Media: (props: MediaProps) => <Media {...props} />,
  PageHero: (props: PageHeroProps) => <PageHero {...props} />,
  PageIntro: (props: PageIntroProps) => <PageIntro {...props} />,
  Pattern: (props: PatternProps) => <Pattern {...props} />,
  PostCard: (props: PostCardProps) => <PostCard {...props} />,
  Section: (props: SectionProps) => <Section {...props} />,
  ServiceCard: (props: ServiceCardProps) => <ServiceCard {...props} />,
  Text: (props: TextProps) => <Text {...props} />,
  TradeCard: (props: TradeCardProps) => <TradeCard {...props} />,
};

export const containerShortcodes = {
  AffiliatesSection: (props: SectionContainerProps) => (
    <AffiliatesSection {...props} />
  ),
  Brand: (props: BrandProps) => <Brand {...props} />,
  CommercialServicesCTA: (props: CTAProps) => (
    <CommercialServicesCTA {...props} />
  ),
  ContactFooter: (props: SectionProps) => <ContactFooter {...props} />,
  ContactForm: (props: FormProps) => <ContactForm {...props} />,
  CupolexBanner: (props: CupolexBannerProps) => <CupolexBanner {...props} />,
  CupolexCarousel: (props: CupolexCarouselProps) => (
    <CupolexCarousel {...props} />
  ),
  CupolexContact: (props: SectionProps) => <CupolexContact {...props} />,
  CupolexIntro: (props: PageIntroProps) => <CupolexIntro {...props} />,
  CupolexIssue: (props: CupolexIssueProps) => <CupolexIssue {...props} />,
  CupolexProblems: (props: CupolexProblemsProps) => (
    <CupolexProblems {...props} />
  ),
  JobsSection: (props: JobsSectionProps) => <JobsSection {...props} />,
  LandingHero: (props: PageHeroProps) => <LandingHero {...props} />,
  MembersSection: (props: SectionContainerProps) => (
    <MembersSection {...props} />
  ),
  MemorialSection: (props: SectionContainerProps) => (
    <MemorialSection {...props} />
  ),
  NewsSection: (props: SectionContainerProps) => <NewsSection {...props} />,
  ResidentialServicesCTA: (props: CTAProps) => (
    <ResidentialServicesCTA {...props} />
  ),
  ServicesSection: (props: ServicesSectionProps) => (
    <ServicesSection {...props} />
  ),
  StatsSection: (props: SectionContainerProps) => <StatsSection {...props} />,
  TradesSection: (props: SectionContainerProps) => <TradesSection {...props} />,
};

export const shortcodes = {
  ...componentShortcodes,
  ...containerShortcodes,
};

export const mdxComponents = {
  ...components,
  ...shortcodes,
};

export const MDX: FC<MDXProps> = ({
  as = "article",
  body,
  children,
  className,
  components,
  ...rest
}) => (
  <Pattern as={as} {...rest} className={classNames(styles.mdx, className)}>
    <MDXProvider components={{ ...mdxComponents, ...components }}>
      {body && <MDXRenderer>{body}</MDXRenderer>}
      {children}
    </MDXProvider>
  </Pattern>
);
