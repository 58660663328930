import React, { FC } from "react";
import { animated, useSpring, config } from "react-spring";
import { useInView } from "react-intersection-observer";

import {
  Grid,
  PatternProps,
  Section,
  SectionProps,
  StatCard,
  Text,
  TextProps,
} from "components";

import { SectionContainerProps } from "../types";

export const StatsSection: FC<SectionContainerProps> = ({
  children,
  container,
  heading = "HH Consulting in Numbers",
  observer,
  spring,
  subheading,
  ...rest
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    ...observer,
  });

  const springProps = {
    config: config.slow,
    opacity: inView ? 1 : 0,
    ...spring,
  };

  const containerProps: PatternProps = {
    as: animated.figure,
    style: useSpring(springProps),
    ...container,
  };

  const headingProps: TextProps = {
    as: animated.h3,
    innerRef: ref,
    mod: "my-8 md:my-10 xl:my-12",
    pattern: "title",
    style: useSpring({
      transform: inView ? "translate3d(0, 0, 0)" : "translate3d(0, -3rem, 0)",
      ...springProps,
    }),
  };

  return (
    <Section
      mod="bg-gray-75 text-center"
      pattern="feature"
      modifier="intro"
      container={containerProps}
      {...(rest as SectionProps)}
    >
      {heading && <Text {...headingProps}>{heading}</Text>}
      <Grid cols="md:grid-cols-3 gap-4 md:gap-5 xl:gap-6">
        <StatCard context="US states we work in" duration={1600} stat="50" />
        <StatCard
          affix="+"
          context="Combined years of experience"
          duration={1800}
          stat="110"
        />
        <StatCard
          affix="+"
          context="Projects completed so far in 2021"
          stat="1600"
        />
      </Grid>
      {subheading && (
        <Text as="h4" pattern="subtitle" mod="my-8 md:my-10 xl:my-12">
          {subheading}
        </Text>
      )}
    </Section>
  );
};
