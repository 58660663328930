import React, { FC } from "react";

import { Grid, PostCard, Section, SectionProps, Text } from "components";
import { useNewsQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const NewsSection: FC<SectionContainerProps> = ({
  children,
  heading,
  subheading,
  ...rest
}) => {
  const { edges } = useNewsQuery();

  return (
    <Section pattern="feature" {...(rest as SectionProps)}>
      {heading && (
        <Text as="h3" mod="mb-4 md:mb-5 xl:mb-6" pattern="title">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" mod="mb-8 md:mb-10 xl:mb-12">
          {subheading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid gap="gap-14 md:gap-16 xl:gap-18">
          {edges.map(
            ({
              node: {
                excerpt,
                frontmatter: { category, date, description, image, title },
                id,
                slug,
              },
            }) => (
              <PostCard
                key={id}
                category={category}
                date={date}
                excerpt={excerpt || description}
                image={image}
                slug={slug}
                title={title}
              />
            )
          )}
        </Grid>
      )}
    </Section>
  );
};
