import React, { FC } from "react";
import classNames from "classnames";

import {
  Box,
  Grid,
  JobCard,
  Link,
  LinkProps,
  Section,
  SectionProps,
  Text,
} from "components";
import { useJobsQuery } from "graphql";

import styles from "./JobsSection.module.css";
import { SectionContainerProps } from "../types";

export interface JobsSectionLink extends LinkProps {
  footnote?: string;
  label?: string;
}

export interface JobsSectionProps extends SectionContainerProps {
  link?: JobsSectionLink;
}

export const JobsSection: FC<JobsSectionProps> = ({
  children,
  className,
  heading = "Join the HH Consulting Team",
  link = {
    to: "mailto:chris@hhengineeringus.com",
    pattern: "button",
    button: "default",
    buttonProps: { color: "primary", size: "lg" },
    label: "Email us your resume",
    footnote:
      "HH Consulting offers competitive salaries and a great work life balance.",
  },
  subheading = "We’re hiring for the following positions:",
  ...rest
}) => {
  const { edges } = useJobsQuery();

  return (
    <Section
      className={classNames(styles.section, className)}
      mod="bg-secondary-light text-secondary-light dark:bg-secondary dark:text-secondary"
      pattern="feature"
      {...(rest as SectionProps)}
    >
      {heading && (
        <Text as="h2" className={styles.heading} pattern="heading">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" className={styles.subheading} pattern="subheading">
          {subheading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid className={styles.grid}>
          {edges.map(
            ({
              node: {
                frontmatter: { icon, title },
                id,
              },
            }) => (
              <JobCard key={id} icon={icon} title={title} />
            )
          )}
        </Grid>
      )}
      {link && (
        <Box className={styles.linkContainer}>
          <Link
            to={link.to}
            pattern={link.pattern}
            button={link.button}
            buttonProps={link.buttonProps}
          >
            {link.label}
          </Link>
          {link.footnote && (
            <Text
              className={styles.footnote}
              mod="text-gray-300 dark:text-gray-700"
              pattern="caption"
            >
              {link.footnote}
            </Text>
          )}
        </Box>
      )}
    </Section>
  );
};
