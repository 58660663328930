import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export type AffiliateFrontmatter = {
  active: boolean;
  image?: GatsbyImage;
  name: string;
  order: number;
  url?: string;
};

export type AffiliateNode = {
  node: {
    frontmatter: AffiliateFrontmatter;
    id: string;
    slug: string;
  };
};

export interface AffiliatesData {
  affiliates: {
    edges: AffiliateNode[];
  };
}

export const useAffiliatesQuery = () => {
  const { affiliates }: AffiliatesData = useStaticQuery(
    graphql`
      query AffiliatesQuery {
        affiliates: allMdx(
          filter: {
            slug: { regex: "/affiliates/" }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              frontmatter {
                active
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  name
                }
                name
                order
                url
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return affiliates;
};
