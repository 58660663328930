import React, { FC } from "react";

import { Box, Media, Section, SectionProps, Text } from "components";
import { useCupolexMediaQuery } from "graphql";

import styles from "./CupolexBanner.module.css";

export interface CupolexBannerProps extends SectionProps {
  heading?: string;
}

export const CupolexBanner: FC<CupolexBannerProps> = ({
  children,
  className,
  heading,
}) => {
  const { banner } = useCupolexMediaQuery();

  return (
    <>
      {(banner || heading) && (
        <Box as="section" className={styles.header}>
          {heading && (
            <Text as="h4" className={styles.heading} pattern="title">
              {heading}
            </Text>
          )}
          {banner && <Media className={styles.media} image={banner} />}
        </Box>
      )}
      <Section on="page-cupolex" pattern="heel" className={className}>
        {children}
      </Section>
    </>
  );
};
