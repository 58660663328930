import React, { ElementType, FC } from "react";
import classNames from "classnames";
import { animated, useSpring, config } from "react-spring";

import { Pattern, PatternProps, Text, TextProps } from "components";

import styles from "./HeroCaption.module.css";

export interface HeroCaptionProps extends PatternProps {
  context?: ElementType;
  heading?: string;
  headingProps?: TextProps;
  meta?: string;
  metaProps?: TextProps;
  spring?: any;
  subheading?: string;
  subheadingProps?: TextProps;
}

export const HeroCaption: FC<HeroCaptionProps> = ({
  as = "figcaption",
  className,
  context,
  heading,
  headingProps,
  is = "caption",
  meta,
  metaProps,
  spring,
  subheading,
  subheadingProps,
  ...rest
}) => {
  const animationProps = {
    config: config.slow,
    from: { opacity: 0, transform: "translate3d(0, 50%, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    ...spring,
  };

  const metaSpring: TextProps = {
    as: animated.small,
    pattern: "meta",
    style: useSpring(animationProps),
    ...metaProps,
  };

  const headingSpring: TextProps = {
    as: animated.h1,
    pattern: "heading",
    style: useSpring({ delay: 250, ...animationProps }),
    ...headingProps,
  };

  const subheadingSpring: TextProps = {
    as: animated.h1,
    mod: "font-thin leading-snug",
    pattern: "subheading",
    style: useSpring({ delay: 500, ...animationProps }),
    ...subheadingProps,
  };

  return (
    <Pattern as={as} is={is} {...(rest as PatternProps)} className={className}>
      {meta && <Text {...metaSpring}>{meta}</Text>}
      {heading && (
        <Text
          {...headingSpring}
          className={classNames(styles.heading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {subheading && <Text {...subheadingSpring}>{subheading}</Text>}
      {context}
    </Pattern>
  );
};
