import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { CTA, Section, SectionProps } from "components";
import { GatsbyImage } from "types/gatsby";

import styles from "./PageIntro.module.css";

export interface PageIntroProps extends SectionProps {
  content?: ReactNode | string;
  image?: GatsbyImage;
}

export const PageIntro: FC<PageIntroProps> = ({
  children,
  className,
  content,
  image,
  ...rest
}) => {
  const sectionProps: SectionProps = {
    modifier: "intro",
    pattern: "cta",
    ...rest,
  };

  return (
    <Section
      className={classNames(styles.section, className)}
      {...sectionProps}
    >
      {children}
      <CTA card={{ className: styles.card }} image={image}>
        {content}
      </CTA>
    </Section>
  );
};
