import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Icon, IconName, Text } from "components";

import styles from "./JobCard.module.css";

export interface JobCardProps extends CardProps {
  icon?: IconName;
  title: string;
}

export const JobCard: FC<JobCardProps> = ({
  children,
  className,
  icon,
  title,
  ...rest
}) => (
  <Card
    mod="text-gray-600 dark:text-gray-800"
    {...(rest as CardProps)}
    className={classNames(styles.card, className)}
  >
    <Icon name={icon} size="7xl" />
    <Text as="h4" className={styles.title} pattern="subtitle">
      {title}
    </Text>
    {children}
  </Card>
);
