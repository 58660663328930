import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Icon, IconName, Pattern, Text } from "components";
import { TradeName } from "graphql";

import styles from "./TradeCard.module.css";

export interface TradeCardProps extends CardProps {
  description?: string;
  icon?: IconName;
  name: TradeName;
}

export const TradeCard: FC<TradeCardProps> = ({
  children,
  className,
  description,
  icon,
  name,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={classNames(styles.card, className)}>
    {icon && (
      <Pattern className={styles.icon} mod="flex">
        <Icon name={icon} size="7xl" />
      </Pattern>
    )}
    {children}
    <Text as="h3" pattern="subtitle" className={styles.name}>
      {name}
    </Text>
    {/* <Pattern mod="col-span-5">
      <Text as="h3" pattern="subtitle" className={styles.name}>
        {icon && <Icon className="flex sm:hidden mr-2" name={icon} size="xl" />}
        {name}
      </Text>
      {description && <Text as="p">{description}</Text>}
    </Pattern> */}
  </Card>
);
