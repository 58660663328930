import React, { FC } from "react";
import Img from "gatsby-image";
import { kebabCase } from "lodash";

import { BoxProps } from "components";
import { useLogosQuery, useSiteMetadataQuery } from "graphql";

export type LogoName = "black" | "dark" | "light" | "white" | string;

export interface BrandLogoProps extends BoxProps {
  logoName?: LogoName;
}

export const BrandLogo: FC<BrandLogoProps> = ({
  className = "w-18 sm:w-20 xl:w-24",
  logoName,
}) => {
  const { logo, logoBlack, logoWhite } = useLogosQuery();
  const { name } = useSiteMetadataQuery();

  switch (logoName) {
    case "black":
      return (
        <Img
          className={className}
          alt={kebabCase(`${name}-${logoBlack.name}`)}
          {...logoBlack.childImageSharp}
        />
      );
    // Note: Put this back if dark and light logos are different.
    // case "dark":
    //   return (
    //     <Img
    //       className={className}
    //       alt={kebabCase(`${name}-${logoDark.name}`)}
    //       {...logoDark.childImageSharp}
    //     />
    //   );
    // case "light":
    //   return (
    //     <Img
    //       className={className}
    //       alt={kebabCase(`${name}-${logoLight.name}`)}
    //       {...logoLight.childImageSharp}
    //     />
    //   );
    case "white":
      return (
        <Img
          className={className}
          alt={kebabCase(`${name}-${logoWhite.name}`)}
          {...logoWhite.childImageSharp}
        />
      );
    default:
      if (!logo?.childImageSharp) return null;

      return (
        <Img
          className={className}
          alt={kebabCase(`${name}-${logo.name}`)}
          {...logo.childImageSharp}
        />
      );
  }
};
