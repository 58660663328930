import React, { FC } from "react";
import { SubmitHandler } from "react-hook-form";

import {
  Button,
  ButtonProps,
  FormField,
  FormFieldProps,
  Grid,
  // GridProps,
  SelectField,
  SelectFieldProps,
  Text,
} from "components";

import styles from "./Form.module.css";

export interface FormProps {
  action?: string;
  button?: boolean | string;
  buttonProps?: ButtonProps;
  className?: string;
  errorMessage?: boolean | string;
  hasErrors?: boolean;
  isSubmitSuccessful?: boolean;
  name: string;
  method?: string;
  successMessage?: boolean | string;
  onSubmit?: SubmitHandler<any>;
}

export const renderField = (field: FormFieldProps | SelectFieldProps) => {
  switch (field.type) {
    case "select":
      return <SelectField {...(field as SelectFieldProps)} />;
    case "text":
    default:
      return <FormField {...(field as FormFieldProps)} />;
  }
};

export const encodeData = (data: any) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

export const Form: FC<FormProps> = ({
  button = "Submit",
  buttonProps,
  children,
  errorMessage = "Fill out all the required fields and try again.",
  hasErrors,
  isSubmitSuccessful,
  successMessage,
  onSubmit,
  ...rest
}) => {
  return (
    <form
      {...(rest as FormProps)}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={onSubmit}
    >
      {children}
      {button && (
        <Grid className={styles.actions}>
          {isSubmitSuccessful
            ? successMessage && (
                <Text className={styles.successMessage}>{successMessage}</Text>
              )
            : errorMessage &&
              hasErrors && (
                <Text className={styles.errorMessage}>{errorMessage}</Text>
              )}
          <Button color="primary" type="submit" {...buttonProps}>
            {button}
          </Button>
        </Grid>
      )}
    </form>
  );
};
