import React, { FC } from "react";

import { PageHero, PageHeroProps } from "components";
import { useTheme } from "contexts";
import { useImagesQuery, useSiteMetadataQuery } from "graphql";

export const LandingHero: FC<PageHeroProps> = ({ children, ...rest }) => {
  const { theme } = useTheme();
  const { name, title } = useSiteMetadataQuery();
  const { hero, heroDark } = useImagesQuery();

  const heroImage = theme === "dark" ? heroDark : hero;

  const heroProps: PageHeroProps = {
    pattern: "landing",
    ...rest,
    caption: {
      heading: title,
      subheading: "Engineering the exceptional.",
      ...rest?.caption,
    },
    media: {
      image: {
        ...heroImage,
        name,
      },
      pattern: "image",
      variant: "background blurry gradient",
      ...rest?.media,
    },
  };

  return <PageHero {...heroProps}>{children}</PageHero>;
};
