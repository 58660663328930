import React, { FC } from "react";

import { Grid, Section, SectionProps, Text, ProcessCard } from "components";
import { useProcessQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const ProcessSection: FC<SectionContainerProps> = ({
  children,
  heading = "Our Process at HH Consulting",
  ...rest
}) => {
  const { edges } = useProcessQuery();

  return (
    <Section pattern="feature" mod="bg-gray-50" {...(rest as SectionProps)}>
      {heading && (
        <Text as="h3" mod="mb-10 md:mb-12 xl:mb-14 text-center" pattern="title">
          {heading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid gap="gap-8 md:gap-10 xl:gap-12" mod="md:grid-cols-2">
          {edges.map(
            ({
              node: {
                frontmatter: { description, order, title },
                id,
              },
            }) => (
              <ProcessCard
                key={id}
                description={description}
                order={order}
                title={title}
              />
            )
          )}
        </Grid>
      )}
    </Section>
  );
};
