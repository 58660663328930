import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Link, Media, Pattern, Text } from "components";
import { GatsbyImage } from "types/gatsby";

import styles from "./PostCard.module.css";

export type PostCategory =
  | "Framing"
  | "Structural Engineering"
  | "Technology"
  | string;

export interface PostCardProps extends CardProps {
  category?: PostCategory;
  date?: string;
  excerpt?: string;
  image?: GatsbyImage;
  slug?: string;
  title: string;
}

export const PostCard: FC<PostCardProps> = ({
  category,
  children,
  className,
  date,
  excerpt,
  image,
  slug,
  title,
  ...rest
}) => (
  <Card
    mod="sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-4 text-gray-400 dark:text-gray-200 hover:text-gray-800 dark:hover:text-gray-75"
    {...(rest as CardProps)}
    className={classNames(styles.card, className)}
  >
    {image && (
      <Link
        to={`/${slug}`}
        className={classNames(styles.image, styles.effects)}
        mod="sm:col-span-2 md:col-span-3 lg:col-span-1"
        pattern="box"
      >
        <Media image={image} />
      </Link>
    )}
    <Pattern mod="sm:col-span-4 md:col-span-5 lg:col-span-3">
      {category && date && (
        <Text
          as="small"
          className={classNames(styles.meta, styles.effects)}
          pattern="meta"
        >
          Posted on {date} in {category}
        </Text>
      )}
      <Link to={`/${slug}`} pattern="box">
        <Text
          as="h4"
          className={classNames(styles.title, styles.effects)}
          mod="text-default hover:text-hover"
          pattern="title"
        >
          {title}
        </Text>
      </Link>
      {excerpt && (
        <Text as="p" className={classNames(styles.excerpt, styles.effects)}>
          {excerpt}
        </Text>
      )}
    </Pattern>
  </Card>
);
