import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { CTA, Section, SectionProps } from "components";
import { useCupolexMediaQuery } from "graphql";

import styles from "./CupolexIssue.module.css";

export interface CupolexIssueProps extends SectionProps {
  content?: ReactNode | string;
  heading?: string;
}

export const CupolexIssue: FC<CupolexIssueProps> = ({
  children,
  className,
  content,
  heading,
}) => {
  const { issue } = useCupolexMediaQuery();

  return (
    <Section
      on="page-cupolex"
      mod="bg-gray-50"
      pattern="cta"
      className={classNames(styles.section, className)}
    >
      {children}
      <CTA
        card={{ className: styles.card }}
        heading={heading}
        headingProps={{ mod: "mb-6 md:mb-7 xl:mb-8", pattern: "subheading" }}
        image={issue}
      >
        {content}
      </CTA>
    </Section>
  );
};
