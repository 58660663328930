import React, { FC } from "react";
import classNames from "classnames";

import {
  Hero,
  HeroProps,
  HeroPattern,
  HeroCaptionProps,
  Media,
  MediaProps,
  PatternProps,
} from "components";

import styles from "./PostHero.module.css";

export interface PostHeroProps extends Omit<HeroProps, "pattern"> {
  hero?: HeroPattern;
}

export const PostHero: FC<PostHeroProps> = ({
  caption,
  children,
  className,
  hero = "post",
  media,
  ...rest
}) => {
  const containerProps: PatternProps = {
    mod: "justify-center",
  };

  const captionProps: HeroCaptionProps = {
    className: styles.caption,
    headingProps: {
      className: styles.heading,
    },
    subheadingProps: {
      className: styles.subheading,
    },
    ...caption,
  };

  const mediaProps: MediaProps = {
    pattern: "image",
    ...media,
  };

  return (
    <Hero
      caption={captionProps}
      container={containerProps}
      pattern={hero}
      {...(rest as HeroProps)}
      className={classNames(styles.hero, className)}
    >
      {media && (
        <Media
          {...mediaProps}
          className={classNames(styles.media, mediaProps.className)}
        />
      )}
      {children}
    </Hero>
  );
};
