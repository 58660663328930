import React, { FC } from "react";
import classNames from "classnames";

import {
  Hero,
  HeroProps,
  HeroPattern,
  HeroCaptionProps,
  MediaProps,
} from "components";

import styles from "./ServiceHero.module.css";

export interface ServiceHeroProps extends Omit<HeroProps, "pattern"> {
  hero?: HeroPattern;
}

export const ServiceHero: FC<ServiceHeroProps> = ({
  caption,
  children,
  className,
  hero = "page",
  media,
  span = "three-fourths",
  ...rest
}) => {
  const captionProps: HeroCaptionProps = {
    className: styles.caption,
    ...caption,
  };

  const mediaProps: MediaProps = {
    pattern: "image",
    variant: "background gradient-service",
    ...media,
  };

  return (
    <Hero
      caption={captionProps}
      media={mediaProps}
      pattern={hero}
      span={span}
      {...(rest as HeroProps)}
      className={classNames(styles.hero, className)}
    >
      {children}
    </Hero>
  );
};
