import React, { FC } from "react";
import classNames from "classnames";

import {
  Hero,
  HeroProps,
  HeroPattern,
  HeroCaptionProps,
  MediaProps,
} from "components";

import styles from "./LandingHero.module.css";

export interface LandingHeroProps extends Omit<HeroProps, "pattern"> {
  hero?: HeroPattern;
}

export const LandingHero: FC<LandingHeroProps> = ({
  caption,
  children,
  className,
  hero = "page",
  media,
  span = "three-fourths",
  ...rest
}) => {
  const captionProps: HeroCaptionProps = {
    ...caption,
    className: classNames(styles.landingCaption, caption?.className),
    headingProps: {
      pattern: "hero",
      ...caption?.headingProps,
    },
  };

  const mediaProps: MediaProps = {
    pattern: "image",
    variant: "background gradient-landing",
    ...media,
  };

  return (
    <Hero
      caption={captionProps}
      media={mediaProps}
      pattern={hero}
      span={span}
      {...(rest as HeroProps)}
      className={classNames(styles.landingHero, className)}
    >
      {children}
    </Hero>
  );
};
