import React, { FC } from "react";
import { kebabCase } from "lodash";

import { Grid, MemberCard, Section, SectionProps, Text } from "components";
import { useMembersQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const MembersSection: FC<SectionContainerProps> = ({
  children,
  heading = "Our Team",
  subheading,
  ...rest
}) => {
  const { edges } = useMembersQuery();

  return (
    <Section {...(rest as SectionProps)}>
      {heading && (
        <Text as="h3" mod="mb-4 md:mb-5 xl:mb-6" pattern="title">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" mod="mb-8 md:mb-10 xl:mb-12">
          {subheading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid cols="sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 xl:gap-10">
          {edges.map(
            ({
              node: {
                frontmatter: { image, name, title },
                id,
              },
            }) => (
              <MemberCard
                id={kebabCase(name)}
                key={id}
                image={image}
                name={name}
                title={title}
              />
            )
          )}
        </Grid>
      )}
    </Section>
  );
};
