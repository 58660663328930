import React, { FC } from "react";
import classNames from "classnames";

import { Card, Grid, GridProps, Link, Media, Text } from "components";

import { CTAButtonProps, CTAProps } from "./types";
import styles from "./DefaultCTA.module.css";

export const CTA: FC<CTAProps> = ({
  button,
  card,
  children,
  className,
  heading,
  headingProps,
  image,
  imageProps,
  ...rest
}) => {
  const buttonProps: CTAButtonProps = {
    mod: "mt-4 md:mt-5 xl:mt-6 hover:bg-primary-hover hover:border-primary-hover hover:text-hover dark:hover:bg-primary dark:hover:border-primary dark:hover:text-default",
    pattern: "button",
    button: "outline",
    buttonProps: { color: "inherit" },
    ...button,
  };

  return (
    <Grid
      {...(rest as GridProps)}
      className={classNames(styles.grid, className)}
    >
      {image && (
        <Media
          {...imageProps}
          className={classNames(styles.image, imageProps?.className)}
          image={image}
        />
      )}
      <Card
        {...card}
        className={classNames("container", styles.card, card?.className)}
      >
        {heading && (
          <Text as="h4" pattern="subtitle" {...headingProps}>
            {heading}
          </Text>
        )}
        {children}
        {button && <Link {...buttonProps}>{button.label}</Link>}
      </Card>
    </Grid>
  );
};
