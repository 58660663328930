import { useStaticQuery, graphql } from "gatsby";

import { ServiceCTAFrontmatter } from "./types";

export interface CommercialServicesData {
  commercialServices: {
    frontmatter: ServiceCTAFrontmatter;
    slug: string;
  };
}

export const useCommercialServicesQuery = () => {
  const { commercialServices }: CommercialServicesData = useStaticQuery(
    graphql`
      query CommercialServicesQuery {
        commercialServices: mdx(
          frontmatter: { page: { eq: "commercial-services" } }
        ) {
          slug
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxHeight: 627, maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  return commercialServices;
};
