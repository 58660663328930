import { useStaticQuery, graphql } from "gatsby";

import { ServiceCTAFrontmatter } from "./types";

export interface ResidentialServicesData {
  residentialServices: {
    frontmatter: ServiceCTAFrontmatter;
    slug: string;
  };
}

export const useResidentialServicesQuery = () => {
  const { residentialServices }: ResidentialServicesData = useStaticQuery(
    graphql`
      query ResidentialServicesQuery {
        residentialServices: mdx(
          frontmatter: { page: { eq: "residential-services" } }
        ) {
          slug
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxHeight: 627, maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  return residentialServices;
};
