import { useStaticQuery, graphql } from "gatsby";

import { IconName } from "components";
import { GatsbyImage } from "types/gatsby";

export type ServiceFrontmatter = {
  categories: string;
  description: string;
  icon: IconName;
  image: GatsbyImage;
  order: number;
  published: boolean;
  subtitle: string;
  tags: string[];
  template: string;
  title: string;
};

export type ServiceNode = {
  node: {
    excerpt: string;
    frontmatter: ServiceFrontmatter;
    id: string;
    slug: string;
  };
};

export interface ServicesData {
  services: {
    edges: ServiceNode[];
  };
}

export const useServicesQuery = () => {
  const { services }: ServicesData = useStaticQuery(
    graphql`
      query ServicesQuery {
        services: allMdx(
          filter: {
            slug: { regex: "/services/" }
            frontmatter: { published: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              excerpt
              frontmatter {
                categories
                description
                icon
                image {
                  childImageSharp {
                    fluid(maxWidth: 640, maxHeight: 480, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  name
                }
                order
                published
                subtitle
                tags
                template
                title
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return services;
};
