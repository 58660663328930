import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export interface CupolexMediaData {
  intro: GatsbyImage;
  issue: GatsbyImage;
  cracks: GatsbyImage;
  sagging: GatsbyImage;
  corrosion: GatsbyImage;
  banner: GatsbyImage;
  preview1: GatsbyImage;
  preview2: GatsbyImage;
  preview3: GatsbyImage;
  preview4: GatsbyImage;
  preview5: GatsbyImage;
}

export const cupolexImageFragment = graphql`
  fragment CupolexImageFragment on File {
    childImageSharp {
      fluid(maxHeight: 800, maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    name
  }
`;

export const useCupolexMediaQuery = () => {
  const {
    intro,
    issue,
    cracks,
    sagging,
    corrosion,
    banner,
    preview1,
    preview2,
    preview3,
    preview4,
    preview5,
  }: CupolexMediaData = useStaticQuery(
    graphql`
      query CupolexMediaQuery {
        intro: file(relativePath: { eq: "cupolex-intro.png" }) {
          ...CupolexImageFragment
        }
        issue: file(relativePath: { eq: "cupolex-issue.png" }) {
          ...CupolexImageFragment
        }
        cracks: file(relativePath: { eq: "cupolex-cracks.png" }) {
          ...CupolexImageFragment
        }
        sagging: file(relativePath: { eq: "cupolex-sagging.png" }) {
          ...CupolexImageFragment
        }
        corrosion: file(relativePath: { eq: "cupolex-corrosion.png" }) {
          ...CupolexImageFragment
        }
        banner: file(relativePath: { eq: "cupolex-banner.png" }) {
          childImageSharp {
            fluid(maxHeight: 264, maxWidth: 1033, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          name
        }
        preview1: file(relativePath: { eq: "cupolex-preview-01.png" }) {
          ...CupolexImageFragment
        }
        preview2: file(relativePath: { eq: "cupolex-preview-02.png" }) {
          ...CupolexImageFragment
        }
        preview3: file(relativePath: { eq: "cupolex-preview-03.png" }) {
          ...CupolexImageFragment
        }
        preview4: file(relativePath: { eq: "cupolex-preview-04.png" }) {
          ...CupolexImageFragment
        }
        preview5: file(relativePath: { eq: "cupolex-preview-05.png" }) {
          ...CupolexImageFragment
        }
      }
    `
  );

  return {
    intro,
    issue,
    cracks,
    sagging,
    corrosion,
    banner,
    preview1,
    preview2,
    preview3,
    preview4,
    preview5,
  };
};
