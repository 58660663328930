import React, { FC } from "react";

import {
  AffiliateCard,
  Grid,
  Link,
  Section,
  SectionProps,
  Text,
} from "components";
import { useAffiliatesQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const AffiliatesSection: FC<SectionContainerProps> = ({
  children,
  heading,
  subheading,
  ...rest
}) => {
  const { edges } = useAffiliatesQuery();

  return (
    <Section pattern="feature" {...(rest as SectionProps)}>
      {heading && (
        <Text as="h3" mod="mb-4 md:mb-5 xl:mb-6" pattern="title">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" mod="mb-8 md:mb-10 xl:mb-12">
          {subheading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid cols="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 xs:gap-5 md:gap-6">
          {edges.map(
            ({
              node: {
                frontmatter: { image, name, url },
                id,
              },
            }) =>
              url ? (
                <Link key={id} pattern="box" to={url}>
                  <AffiliateCard image={image} name={name} />
                </Link>
              ) : (
                <AffiliateCard key={id} image={image} name={name} />
              )
          )}
        </Grid>
      )}
    </Section>
  );
};
