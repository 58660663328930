import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export interface LogosData {
  logo: GatsbyImage;
  logoBlack: GatsbyImage;
  logoDark: GatsbyImage;
  logoLight: GatsbyImage;
  logoWhite: GatsbyImage;
}

export const logoImageFragment = graphql`
  fragment LogoImageFragment on File {
    childImageSharp {
      fluid(
        maxHeight: 800
        maxWidth: 800
        quality: 90
        traceSVG: { color: "#d30324" }
      ) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
    name
  }
`;

export const useLogosQuery = () => {
  const { logo, logoBlack, logoDark, logoLight, logoWhite }: LogosData =
    useStaticQuery(
      graphql`
        query LogoQuery {
          logo: file(relativePath: { eq: "logo.png" }) {
            ...LogoImageFragment
          }
          logoBlack: file(relativePath: { eq: "logo-black.png" }) {
            ...LogoImageFragment
          }
          logoDark: file(relativePath: { eq: "logo-dark.png" }) {
            ...LogoImageFragment
          }
          logoLight: file(relativePath: { eq: "logo-light.png" }) {
            ...LogoImageFragment
          }
          logoWhite: file(relativePath: { eq: "logo-white.png" }) {
            ...LogoImageFragment
          }
        }
      `
    );

  return {
    logo,
    logoBlack,
    logoDark,
    logoLight,
    logoWhite,
  };
};
