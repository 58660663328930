import React, { FC } from "react";
import { animated, useSpring } from "react-spring";
import classNames from "classnames";

import {
  Hero,
  HeroProps,
  HeroPattern,
  HeroCaptionProps,
  MediaProps,
} from "components";
import { Brand, BrandProps } from "containers";

import styles from "./PageHero.module.css";

export type PageHeroPattern = "article" | "default" | "landing";

export interface PageHeroProps extends Omit<HeroProps, "pattern"> {
  brand?: BrandProps;
  hero?: HeroPattern;
  pattern?: PageHeroPattern;
  showBrand?: boolean;
}

export const getPageHeroSpan = (pattern: PageHeroPattern) => {
  switch (pattern) {
    case "article":
      return "half";
    case "landing":
      return "eleven-twelfths";
    case "default":
      return "three-fourths";
    default:
      return "auto";
  }
};

export const PageHero: FC<PageHeroProps> = ({
  brand,
  caption,
  children,
  className,
  hero = "page",
  media,
  pattern = "default",
  showBrand = true,
  span = "full",
  spring,
  ...rest
}) => {
  const animation = useSpring({
    from: { opacity: 0, transform: "translate3d(0, -25%, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    ...spring,
  });

  const captionProps: HeroCaptionProps = {
    className: styles.caption,
    mod: showBrand ? "-mt-18" : "my-4",
    order: 1,
    ...caption,
  };

  const mediaProps: MediaProps = {
    pattern: "pattern",
    variant: "background graph",
    ...media,
  };

  const brandProps: BrandProps = {
    as: animated.div,
    logoProps: {
      className: "w-full max-w-xs",
    },
    showContent: false,
    showLink: false,
    style: animation,
    ...brand,
  };

  return (
    <Hero
      caption={captionProps}
      media={mediaProps}
      pattern={hero}
      span={span || getPageHeroSpan(pattern)}
      {...(rest as HeroProps)}
      className={classNames(styles.hero, className)}
    >
      {showBrand && <Brand {...brandProps} />}
      {children}
    </Hero>
  );
};
