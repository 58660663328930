import React, { FC } from "react";
import classNames from "classnames";

import {
  CTA,
  CTAProps,
  CTAButtonProps,
  Section,
  SectionProps,
} from "components";
import { useCommercialServicesQuery } from "graphql";

import styles from "./CommercialServicesCTA.module.css";

export const CommercialServicesCTA: FC<CTAProps> = ({
  button,
  className,
  heading,
  ...rest
}) => {
  const {
    frontmatter: { description, image, title },
    slug,
  } = useCommercialServicesQuery();

  const sectionProps: SectionProps = {
    pattern: "cta",
    ...rest,
  };

  const buttonProps: CTAButtonProps = {
    ...button,
    to: `/${slug}`,
    label: button?.label || title,
  };

  return (
    <Section
      {...sectionProps}
      className={classNames(styles.section, className)}
    >
      <CTA
        button={buttonProps}
        card={{ className: styles.card }}
        heading={heading || description}
        image={image}
        imageProps={{ className: styles.image }}
      />
    </Section>
  );
};
