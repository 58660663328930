import React, { FC } from "react";

import {
  FormField,
  FormFieldProps,
  SelectInput,
  SelectInputProps,
  SelectOption,
} from "components";

export interface SelectFieldProps extends FormFieldProps {
  options: SelectOption[];
  selectProps?: SelectInputProps;
}

export const SelectField: FC<SelectFieldProps> = ({
  is = "field",
  children,
  className,
  error,
  errorMessage = "This field is required",
  label,
  name,
  options,
  register,
  selectProps,
  type = "select",
  ...rest
}) => (
  <FormField
    is={is}
    {...(rest as FormFieldProps)}
    label={label}
    name={name}
    error={error}
    errorMessage={errorMessage}
  >
    <SelectInput
      {...selectProps}
      label={label}
      name={name}
      options={options}
      // register={register}
    />
    {children}
  </FormField>
);
