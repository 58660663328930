import React, { FC } from "react";
import classNames from "classnames";

import { Box, Card, CardProps, Media, Text } from "components";
import { MemberName } from "graphql";
import { GatsbyImage } from "types/gatsby";

import styles from "./AuthorCard.module.css";

export interface AuthorCardProps extends CardProps {
  bio?: string;
  caption?: string;
  image?: GatsbyImage;
  name: MemberName;
}

export const AuthorCard: FC<AuthorCardProps> = ({
  bio,
  caption,
  children,
  className,
  image,
  name,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={classNames(styles.card, className)}>
    {image && <Media className={styles.image} image={image} />}
    {children}
    <Box className={styles.info}>
      {caption && (
        <Text
          as="small"
          mod="text-gray-400 dark:text-gray-300"
          pattern="caption"
        >
          {caption}
        </Text>
      )}
      <Text as="h4" className={styles.name} pattern="subheading">
        {name}
      </Text>
      {bio && (
        <Text
          as="p"
          mod="text-gray-400 dark:text-gray-100"
          className={styles.bio}
        >
          {bio}
        </Text>
      )}
    </Box>
  </Card>
);
