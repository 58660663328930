import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Media, Text } from "components";
import { GatsbyImage } from "types/gatsby";

import styles from "./AffiliateCard.module.css";

export interface AffiliateCardProps extends CardProps {
  image?: GatsbyImage;
  name: string;
  showName?: boolean;
}

export const AffiliateCard: FC<AffiliateCardProps> = ({
  children,
  className,
  image,
  name,
  showName = true,
  ...rest
}) => (
  <Card
    mod="bg-paper border-2 border-gray-25 dark:border-gray-300 text-paper hover:bg-primary hover:text-primary hover:border-primary dark:hover:border-primary"
    {...(rest as CardProps)}
    className={classNames(styles.card, !showName && styles.featured, className)}
  >
    {image && <Media image={image} />}
    {children}
    {showName && (
      <Text as="h4" className={styles.name}>
        {name}
      </Text>
    )}
  </Card>
);
