import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export interface MemorialData {
  image: GatsbyImage;
}

export const useMemorialQuery = () => {
  const { image }: MemorialData = useStaticQuery(
    graphql`
      query MemorialQuery {
        image: file(relativePath: { eq: "members/assets/asad-qureshi.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 1200, maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          name
        }
      }
    `
  );

  return {
    image,
  };
};
