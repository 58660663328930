import React, { FC } from "react";
import { animated, useSpring, config } from "react-spring";
import { useInView } from "react-intersection-observer";

import { Box, Grid, Media, Section, SectionProps, Text } from "components";
import { useMemorialQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const MemorialSection: FC<SectionContainerProps> = ({
  children,
  observer,
  spring,
  ...rest
}) => {
  const { image } = useMemorialQuery();

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    ...observer,
  });

  const springProps = {
    config: config.slow,
    opacity: inView ? 1 : 0,
    transform: inView ? "translate3d(0, 0, 0)" : "translate3d(0, 25%, 0)",
    ...spring,
  };

  return (
    <Section
      mod="bg-gray-25"
      pattern="feature"
      modifier="intro"
      {...(rest as SectionProps)}
    >
      <Grid
        as={animated.div}
        innerRef={ref}
        className="gap-4 md:gap-6 xl:gap-8 sm:grid-cols-3 lg:grid-cols-4 items-center"
        style={useSpring(springProps)}
      >
        <Text className="sm:hidden" as="p">
          It's with the heaviest of hearts to announce that{" "}
          <strong>Asad Qureshi</strong>, our beloved friend and colleague,
          passed away on Wednesday, March 3.
        </Text>
        <Media className="sm:col-span-1" image={image} />
        <Box className="sm:col-span-2 lg:col-span-3">
          <Text className="hidden sm:block mb-4 md:mb-6 xl:mb-8" as="p">
            It's with the heaviest of hearts to announce that{" "}
            <strong>Asad Qureshi</strong>, our beloved friend and colleague,
            passed away on Wednesday, March 3.
          </Text>
          <Text as="p">
            Asad dedicated his life to structural engineering and was with HH
            Consulting from the very beginning. He was incredibly kind and truly
            family—we will miss him deeply. Rest in peace, dear friend.
          </Text>
        </Box>
      </Grid>
      {children}
    </Section>
  );
};
