import React, { FC } from "react";
import classNames from "classnames";

import { CTA, MDX, Section, SectionProps } from "components";
import { GatsbyImage } from "types/gatsby";

import styles from "./ServiceIntro.module.css";

export interface ServiceIntroProps extends SectionProps {
  content?: string;
  image?: GatsbyImage;
}

export const ServiceIntro: FC<ServiceIntroProps> = ({
  children,
  className,
  content,
  image,
  ...rest
}) => {
  const sectionProps: SectionProps = {
    modifier: "intro service",
    pattern: "cta",
    ...rest,
  };

  return (
    <Section
      className={classNames(styles.section, className)}
      {...sectionProps}
    >
      {children}
      <CTA
        card={{ className: styles.card }}
        image={image}
        imageProps={{ className: styles.image }}
      >
        {content && <MDX body={content} />}
      </CTA>
    </Section>
  );
};
