import React, { FC } from "react";
import classNames from "classnames";

import { Link, Section, SectionProps, Text } from "components";
import { useSiteMetadataQuery } from "graphql";

export const CupolexContact: FC<SectionProps> = ({ children, className }) => {
  const { organization } = useSiteMetadataQuery();

  return (
    <Section
      as="footer"
      on="page-cupolex"
      className={classNames("bg-gray-25", className)}
    >
      <Text as="h4" pattern="subheading" className="mb-4 md:mb-6 xl:mb-8">
        A big “thank you!” to{" "}
        <Link
          to="http://cupolex.com/"
          target="_blank"
          rel="noopener"
          size="inherit"
        >
          CUPOLEX
        </Link>{" "}
        and{" "}
        <Link
          to="https://admiralpoolsolutions.com/"
          target="_blank"
          rel="noopener"
          size="inherit"
        >
          Admiral Pools
        </Link>{" "}
        for taking a chance and partnering with us on this pioneering project.
      </Text>
      {children}
      <Text as="p" className="mb-2 md:mb-3 xl:mb-4">
        To learn more, contact HH Consulting.
      </Text>
      {organization?.email && (
        <Text as="p">
          Email:{" "}
          <Link to={`mailto:${organization.email}`}>{organization.email}</Link>
        </Text>
      )}
      {organization?.telephone && (
        <Text as="p">
          Phone:{" "}
          <Link to={`tel:${organization.telephone}`}>
            {organization.telephone}
          </Link>
        </Text>
      )}
    </Section>
  );
};
