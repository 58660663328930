import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Media, Text } from "components";
import { MemberName, MemberTitle } from "graphql";
import { GatsbyImage } from "types/gatsby";

import styles from "./MemberCard.module.css";

export interface MemberCardProps extends CardProps {
  bio?: string;
  image?: GatsbyImage;
  name: MemberName;
  title?: MemberTitle;
}

export const MemberCard: FC<MemberCardProps> = ({
  bio,
  children,
  className,
  image,
  name,
  title,
  ...rest
}) => (
  <Card {...(rest as CardProps)} className={classNames(styles.card, className)}>
    {image && <Media image={image} />}
    {children}
    <Text as="h4" className={styles.name} pattern="subtitle">
      {name}
    </Text>
    {title && (
      <Text as="h5" className={styles.title}>
        {title}
      </Text>
    )}
    {bio && (
      <Text as="p" className={styles.bio}>
        {bio}
      </Text>
    )}
  </Card>
);
