import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export type NewsPostFrontmatter = {
  author?: string;
  category: string;
  date: string;
  description?: string;
  image: GatsbyImage;
  published: boolean;
  tags?: string[];
  title: string;
};

export type NewsPostNode = {
  node: {
    excerpt: string;
    frontmatter: NewsPostFrontmatter;
    id: string;
    slug: string;
  };
};

export interface NewsData {
  posts: {
    edges: NewsPostNode[];
  };
}

export const useNewsQuery = () => {
  const { posts }: NewsData = useStaticQuery(
    graphql`
      query NewsQuery {
        posts: allMdx(
          filter: {
            slug: { regex: "/news/" }
            frontmatter: { published: { eq: true } }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              excerpt
              frontmatter {
                author
                category
                date(formatString: "MMMM DD, YYYY")
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 640, maxHeight: 480, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  name
                }
                published
                tags
                title
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return posts;
};
