import React, { FC } from "react";

import { AuthorCard, Section, SectionProps } from "components";
import { MemberName, MemberNode, useMembersQuery } from "graphql";

import { SectionContainerProps } from "../types";

export interface AuthorSectionProps extends SectionContainerProps {
  author?: MemberName;
  caption?: string;
}

export const getAuthorMemberData = (
  author: MemberName | undefined,
  members: MemberNode[]
) =>
  members.filter(
    ({
      node: {
        frontmatter: { name },
      },
    }) => author === name
  );

export const AuthorSection: FC<AuthorSectionProps> = ({
  author,
  caption = "Written & edited by",
  children,
  ...rest
}) => {
  const { edges } = useMembersQuery();
  const member = getAuthorMemberData(author, edges)[0]?.node?.frontmatter;

  if (!member) return null;

  const { bio, image, name } = member;

  return (
    <Section pattern="container" {...(rest as SectionProps)}>
      <AuthorCard bio={bio} caption={caption} image={image} name={name} />
      {children}
    </Section>
  );
};
