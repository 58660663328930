import React, { FC } from "react";
import classNames from "classnames";

import { Pattern, PatternProps } from "components";

import styles from "./Card.module.css";

export type CardPattern = "bordered" | "default";

export interface CardProps extends PatternProps {
  observer?: any;
  pattern?: CardPattern;
  spring?: any;
}

export const Card: FC<CardProps> = ({
  children,
  className,
  is = "card",
  pattern = "default",
  ...rest
}) => (
  <Pattern
    is={is}
    {...(rest as PatternProps)}
    className={classNames(styles.card, styles[pattern], className)}
  >
    {children}
  </Pattern>
);
