import React, { FC } from "react";

import { Grid, Section, SectionProps, Text, TradeCard } from "components";
import { useTradesQuery } from "graphql";

import { SectionContainerProps } from "../types";

export const TradesSection: FC<SectionContainerProps> = ({
  children,
  heading = "Looking for experts in other disciplines?",
  subheading = "We’ll help you find skilled professionals for your project. ",
  ...rest
}) => {
  const { edges } = useTradesQuery();

  return (
    <Section pattern="feature" {...(rest as SectionProps)}>
      {heading && (
        <Text as="h3" mod="mb-4 md:mb-5 xl:mb-6" pattern="title">
          {heading}
        </Text>
      )}
      {subheading && (
        <Text as="p" mod="mb-8 md:mb-10 xl:mb-12">
          {subheading}
        </Text>
      )}
      {children}
      {edges?.length && (
        <Grid
          gap="gap-8 md:gap-10 xl:gap-12"
          mod="grid-cols-2 sm:grid-cols-3 lg:grid-cols-5"
        >
          {edges.map(
            ({
              node: {
                frontmatter: { icon, name },
                id,
              },
            }) => (
              <TradeCard
                key={id}
                // description={description}
                icon={icon}
                name={name}
              />
            )
          )}
        </Grid>
      )}
    </Section>
  );
};
