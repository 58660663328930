import React, { FC } from "react";

import { Text, TextProps } from "components";
import { useSiteMetadataQuery } from "graphql";

export interface BrandNameProps extends TextProps {
  isShort?: boolean;
}

export const BrandName: FC<BrandNameProps> = ({
  as = "h5",
  children,
  className,
  isShort,
  ...rest
}) => {
  const { acronym, name, title } = useSiteMetadataQuery();

  return (
    <Text
      as={as}
      mod="font-semibold leading-snug tracking-tight uppercase"
      pattern="caption"
      {...rest}
    >
      {children
        ? children
        : isShort
        ? acronym
          ? acronym
          : name
        : title
        ? title
        : name}
    </Text>
  );
};
