import React, { FC } from "react";

import { PageIntro, PageIntroProps } from "components";
import { useCupolexMediaQuery } from "graphql";

export const CupolexIntro: FC<PageIntroProps> = ({ children, content }) => {
  const { intro } = useCupolexMediaQuery();

  return (
    <PageIntro
      on="page-cupolex"
      mod="bg-gray-25"
      image={intro}
      content={content}
    >
      {children}
    </PageIntro>
  );
};
