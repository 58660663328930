"use-strict";

import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { ThemeProvider } from "./src/contexts";

import "./assets/styles/main.css";

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <ThemeProvider>{element}</ThemeProvider>
  </HelmetProvider>
);
