module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["347441842"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"/opt/build/repo/assets/media/logo.png","name":"HH Consulting","short_name":"HH","start_url":"/","theme_color":"#b10202","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58ed1184fdcb1253406cb844cf84aa8c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/templates/default/DefaultTemplate.tsx"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files","gatsby-remark-responsive-iframe",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"maxHeight":627,"linkImagesToOriginal":false}}],"remarkPlugins":[null,null],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
