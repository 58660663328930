import React, { FC } from "react";
import classNames from "classnames";

import { Card, CardProps, Icon, IconName, Text } from "components";

import styles from "./ServiceCard.module.css";

export interface ServiceCardProps extends CardProps {
  description?: string;
  icon?: IconName;
  title: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  children,
  className,
  description,
  icon,
  title,
  ...rest
}) => (
  <Card
    {...(rest as CardProps)}
    className={classNames(styles.card, className)}
    mod="border-gray-25 dark:border-gray-800 hover:border-primary dark:hover:border-primary text-gray-500 dark:text-gray-100"
    pattern="bordered"
  >
    {icon && (
      <Icon
        className={classNames(styles.icon, styles.effects)}
        name={icon}
        size="7xl"
      />
    )}
    <Text as="h3" className={classNames(styles.title, styles.effects)}>
      {title}
    </Text>
    {description && (
      <Text as="p" className={classNames(styles.description, styles.effects)}>
        {description}
      </Text>
    )}
  </Card>
);
