import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "types/gatsby";

export interface ImagesData {
  hero: GatsbyImage;
  heroDark: GatsbyImage;
}

export const imageFragment = graphql`
  fragment ImageFragment on File {
    childImageSharp {
      fluid(maxHeight: 800, maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    name
  }
`;

export const useImagesQuery = () => {
  const { hero, heroDark }: ImagesData = useStaticQuery(
    graphql`
      query ImagesQuery {
        hero: file(relativePath: { eq: "hero.png" }) {
          ...ImageFragment
        }
        heroDark: file(relativePath: { eq: "hero-dark.png" }) {
          ...ImageFragment
        }
      }
    `
  );

  return {
    hero,
    heroDark,
  };
};
