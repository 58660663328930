import React, { FC, useEffect, useState } from "react";
import { animated, useSpring, config } from "react-spring";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

import { Card, CardProps, Text, TextProps } from "components";

import styles from "./StatCard.module.css";

export interface StatCardProps extends CardProps {
  affix?: string;
  context?: string;
  duration?: number;
  prefix?: string;
  stat: string;
  statProps?: TextProps;
}

const easeOutQuad = (t: number) => t * (2 - t);
const frameDuration = 1000 / 60;

export const StatCard: FC<StatCardProps> = ({
  affix,
  children,
  className,
  context,
  duration = 2000,
  observer,
  prefix,
  spring,
  stat,
  statProps,
  ...rest
}) => {
  const countTo = parseInt(stat, 10);
  const [count, setCount] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    ...observer,
  });

  const springProps = {
    config: config.slow,
    opacity: inView ? 1 : 0,
    ...spring,
  };

  const cardProps: CardProps = {
    as: animated.div,
    innerRef: ref,
    style: useSpring(springProps),
    ...rest,
  };

  useEffect(() => {
    let frame = 0;
    const totalFrames = Math.round(duration / frameDuration);
    const counter = setInterval(() => {
      frame++;
      const progress = easeOutQuad(frame / totalFrames);
      setCount(countTo * progress);

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }, [inView]);

  return (
    <Card {...cardProps} className={classNames(styles.card, className)}>
      <Text pattern="hero" {...statProps}>
        {prefix}
        {Math.floor(count)}
        {affix}
      </Text>
      {context && <Text>{context}</Text>}
      {children}
    </Card>
  );
};

// Count up animation reference:
// - https://jshakespeare.com/simple-count-up-number-animation-javascript-react/
