import React, { FC } from "react";
import classNames from "classnames";

import { Box, Link, Pattern, PatternProps } from "components";
import { useTheme } from "contexts";
import { useSiteMetadataQuery } from "graphql";

import {
  BrandLogo,
  BrandLogoProps,
  BrandName,
  BrandNameProps,
  LogoName,
} from "./components";
import styles from "./Brand.module.css";

export interface BrandProps extends PatternProps {
  isShort?: boolean;
  linkTo?: string;
  logoName?: LogoName;
  logoDark?: string;
  logoLight?: string;
  logoProps?: BrandLogoProps;
  nameProps?: BrandNameProps;
  showContent?: boolean;
  showLink?: boolean;
  showLogo?: boolean;
  showName?: boolean;
  showNumber?: boolean;
}

export const Brand: FC<BrandProps> = ({
  children,
  className,
  isShort = false,
  linkTo = "/",
  logoName,
  logoDark = "dark",
  logoLight = "light",
  logoProps,
  nameProps,
  showContent = true,
  showLink = true,
  showLogo = true,
  showName = true,
  showNumber = false,
  ...rest
}) => {
  const { theme } = useTheme();
  const { organization } = useSiteMetadataQuery();

  const getLogoName = () =>
    logoName
      ? logoName
      : theme
      ? theme === "dark"
        ? logoDark
        : logoLight
      : undefined;

  return (
    <Pattern
      {...(rest as PatternProps)}
      className={classNames(styles.brand, className)}
    >
      {showLogo && showLink ? (
        <Link className={styles.link} to={linkTo}>
          <BrandLogo logoName={getLogoName()} {...logoProps} />
        </Link>
      ) : (
        <BrandLogo logoName={getLogoName()} {...logoProps} />
      )}
      {showContent ? (
        <Box as="span" className={classNames("mx-1 md:mx-2", styles.content)}>
          {showName ? (
            showLink ? (
              <Link className={styles.link} to={linkTo}>
                <BrandName isShort={isShort} {...nameProps}>
                  {children}
                </BrandName>
              </Link>
            ) : (
              <BrandName isShort={isShort} {...nameProps}>
                {children}
              </BrandName>
            )
          ) : null}
          {showNumber && organization?.telephone && (
            <Link
              className={styles.link}
              text="caption"
              to={`tel:${organization.telephone}`}
            >
              {organization.telephone}
            </Link>
          )}
        </Box>
      ) : (
        children
      )}
    </Pattern>
  );
};
