import React, { FC } from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { camelCase } from "lodash";

import { Pattern, PatternProps } from "components";
import { GatsbyImage } from "types/gatsby";

import styles from "./Media.module.css";

export type MediaPattern = "image" | "pattern" | "video";

export type MediaVariant =
  | "background"
  | "blurry"
  | "gradient"
  | "gradient-landing"
  | "gradient-post"
  | "gradient-service"
  | "graph"
  | string;

export interface MediaProps<Media extends HTMLElement = HTMLDivElement>
  extends PatternProps<Media> {
  image: GatsbyImage;
  pattern?: MediaPattern;
  variant?: MediaVariant;
}

export const Media: FC<MediaProps> = ({
  children,
  className,
  image,
  is = "media",
  pattern = "image",
  variant,
  ...rest
}) => {
  const getVariants = () => [
    variant?.split(" ").map((variant) => styles[camelCase(variant)]),
  ];

  return (
    <Pattern
      is={is}
      {...(rest as MediaProps)}
      className={classNames(
        styles.media,
        getVariants(),
        styles[pattern],
        className
      )}
    >
      {image && (
        <Img
          alt={image.name || `media-${pattern}`}
          {...image.childImageSharp}
        />
      )}
      {children}
    </Pattern>
  );
};
