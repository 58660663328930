import { useStaticQuery, graphql } from "gatsby";

export type ProcessFrontmatter = {
  active: boolean;
  description?: string;
  order: number;
  title: string;
};

export type ProcessNode = {
  node: {
    frontmatter: ProcessFrontmatter;
    id: string;
    slug: string;
  };
};

export interface ProcessData {
  process: {
    edges: ProcessNode[];
  };
}

export const useProcessQuery = () => {
  const { process }: ProcessData = useStaticQuery(
    graphql`
      query ProcessQuery {
        process: allMdx(
          filter: {
            slug: { regex: "/process/" }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          edges {
            node {
              frontmatter {
                active
                description
                order
                title
              }
              id
              slug
            }
          }
        }
      }
    `
  );

  return process;
};
